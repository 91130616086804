import classNames from "classnames"
import { useField } from "formik"
import React, { useState, useEffect } from "react"

import InputError from "./InputError"
import { Color } from "../../../../constants/V2/color"
import Typography from "../Typography"

import { getBorderColorClass, getTextColorClass } from "@utils/V2/color"

// TODO: Whole input styling and props is 90% the same as TextInput and Phone number input. Look into refactoring to one code
export interface SelectOption {
  label: string
  value: string | number
}

export interface Props {
  id: string
  name: string
  required?: boolean
  className?: string
  disabled?: boolean
  placeholder: string
  onFocus?: () => void
  options: SelectOption[]
  color?: Color.White | Color.Charcoal
  ["data-test-id"]?: string
}

const DropdownInput = ({
  id,
  name,
  onFocus,
  options,
  className,
  placeholder,
  color = Color.Charcoal,
  required = false,
  disabled = false,
  ...props
}: Props): JSX.Element => {
  const [focus, setFocus] = useState(false)
  const [field, meta, helpers] = useField(id)

  const hasValueOrFocus = !!field.value || focus
  const hasError = meta.touched && meta.error

  useEffect(() => {
    helpers.setTouched(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className={classNames("w-full", { "opacity-50": disabled }, className)}
    >
      <div className="relative w-full rounded-sm">
        <label
          htmlFor={id}
          className={classNames(
            "absolute left-16-v2 transition-all ease-linear duration-200 flex gap-8-v2 pointer-events-none",
            {
              "top-10-v2": hasValueOrFocus,
            },
            {
              "top-24-v2": !hasValueOrFocus,
            }
          )}
        >
          <Typography
            weight={hasValueOrFocus ? "medium" : "book"}
            font="grotesk"
            color={color}
            text={required ? `${placeholder} *` : placeholder}
            size={hasValueOrFocus ? "subscript-sm" : "body-sm"}
            className={classNames(
              { uppercase: hasValueOrFocus },
              "transition-all duration-200"
            )}
          />
        </label>

        <select
          id={id}
          {...field}
          {...props}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          className={classNames(
            "w-full px-16-v2 pb-10-v2 pt-[28px] border border-charcoal-v2/20 rounded-sm text-ellipsis text-body-sm-v2 overflow-y-hidden appearance-none cursor-pointer bg-transparent focus:border-blue-v2",
            getTextColorClass(color),
            { "border-orange-v2": hasError },
            {
              "border-charcoal-v2/20 hover:border-charcoal-v2":
                color === Color.Charcoal && !hasError,
            },
            {
              "border-white-v2/20 hover:border-white-v2":
                color === Color.White && !hasError,
            }
          )}
          onBlurCapture={() => setFocus(false)}
          onChangeCapture={() => setFocus(false)}
          onFocus={() => {
            setFocus(true)
            if (onFocus) {
              onFocus()
            }
          }}
        >
          <option value=""></option>
          {options.map((option) => (
            <option key={option.label} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        <span
          className={classNames(
            "absolute border-l-[5px] border-l-transparent focus:border-t-blue-v2 border-r-[5px] border-r-transparent opacity-50 right-16-v2 top-[28px] transition-all ease-linear duration-200 pointer-events-none",
            getBorderColorClass(color),
            focus ? "border-b-8" : "border-t-8"
          )}
        />
      </div>

      {hasError ? (
        <InputError
          error={`* ${hasError}`}
          data-test-id={`input-error-${props["data-test-id"]}`}
        />
      ) : null}
    </div>
  )
}

export default DropdownInput
