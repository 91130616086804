import React from "react"

import { WEB_TO_LEAD_FORM_IDS } from "../../../../constants/V2/salesForm"
import WebToLeadForm from "../../../V2/Forms/WebToLeadForm"

const SalesForm: React.FC<Storyblok.BlokProps<Storyblok.ModalSalesForm>> = ({
  blok,
}) => {
  const preselectedProduct =
    blok.preselectedProduct && blok.preselectedProduct in WEB_TO_LEAD_FORM_IDS
      ? WEB_TO_LEAD_FORM_IDS[
          blok.preselectedProduct as keyof typeof WEB_TO_LEAD_FORM_IDS
        ]
      : undefined

  return (
    <WebToLeadForm
      preselectedProduct={preselectedProduct}
      inModalId={blok.inModalId}
    />
  )
}

export default SalesForm
