import classNames from "classnames"
import React from "react"

import { Color } from "../../../constants/V2/color"
import {
  WEB_TO_LEAD_FORM_IDS,
  IMAGE_URL,
} from "../../../constants/V2/salesForm"
import CheckBox from "../../elements/V2/Inputs/Checkbox"
import Typography from "../../elements/V2/Typography"

interface Props {
  touched: {
    [x: string]: string | boolean | undefined
  }
  errors: {
    [x: string]: string | boolean | undefined
    checkboxErrorMessage?: string
    radioErrorMessage?: string
  }
  preselectedProduct?: string
}

const FormProductOptions: React.FC<Props> = ({
  touched,
  errors,
  preselectedProduct,
}) => {
  const hasError =
    errors.checkboxErrorMessage &&
    (touched[WEB_TO_LEAD_FORM_IDS.yocoGo] ||
      touched[WEB_TO_LEAD_FORM_IDS.yocoKhumo] ||
      touched[WEB_TO_LEAD_FORM_IDS.yocoNeoTouch] ||
      touched[WEB_TO_LEAD_FORM_IDS.yocoGateway] ||
      touched[WEB_TO_LEAD_FORM_IDS.yocoLink] ||
      touched[WEB_TO_LEAD_FORM_IDS.yocoInvoices] ||
      touched[WEB_TO_LEAD_FORM_IDS.yocoPointOfSale])

  if (preselectedProduct) {
    return (
      <div className="hidden">
        <input
          type="checkbox"
          id={preselectedProduct}
          name={preselectedProduct}
          value="true"
          readOnly={true}
          checked={!!preselectedProduct}
        />
      </div>
    )
  } else {
    return (
      <>
        <Typography
          color={Color.Black}
          text="3. What Yoco products are you interested in? *"
          font="grotesk"
          size="body-lg"
          weight="medium"
          className="mb-8-v2"
        />
        <Typography
          color={Color.Charcoal}
          text="* Select at least 1 option below"
          font="grotesk"
          size="body-md"
          weight="book"
          className={classNames(
            "mb-16-v2",
            hasError ? "!text-orange-v2 opacity-100" : "opacity-70"
          )}
        />

        <div className="flex flex-wrap flex-col gap-12-v2 sm-v2:flex-row mb-32-v2">
          <div className="w-full sm-v2:w-[269px] flex flex-col gap-16-v2">
            <CheckBox
              label="Neo Touch"
              id={WEB_TO_LEAD_FORM_IDS.yocoNeoTouch}
              image={IMAGE_URL.yocoNeoTouch}
              data-test-id="yoco-neo-checkbox"
            />
            <CheckBox
              label="Khumo"
              id={WEB_TO_LEAD_FORM_IDS.yocoKhumo}
              image={IMAGE_URL.yocoKhumo}
              data-test-id="yoco-khumo-checkbox"
            />
            <CheckBox
              label="Khumo Print"
              id={WEB_TO_LEAD_FORM_IDS.yocoKhumoPrint}
              image={IMAGE_URL.yocoKhumoPrint}
              data-test-id="yoco-khumo-print-checkbox"
            />
            <CheckBox
              label="Go"
              id={WEB_TO_LEAD_FORM_IDS.yocoGo}
              image={IMAGE_URL.yocoGo}
              data-test-id="yoco-go-checkbox"
            />
          </div>
          <div className="w-full sm-v2:w-[269px] flex flex-col gap-16-v2">
            <CheckBox
              label="Point of sale"
              id={WEB_TO_LEAD_FORM_IDS.yocoPointOfSale}
              image={IMAGE_URL.yocoPointOfSale}
              data-test-id="yoco-pointofsales-checkbox"
            />
            <CheckBox
              label="Link"
              id={WEB_TO_LEAD_FORM_IDS.yocoLink}
              image={IMAGE_URL.yocoLink}
              data-test-id="yoco-link-checkbox"
            />
            <CheckBox
              label="Invoices"
              id={WEB_TO_LEAD_FORM_IDS.yocoInvoices}
              image={IMAGE_URL.yocoInvoices}
              data-test-id="yoco-invoices-checkbox"
            />
            <CheckBox
              label="Gateway"
              id={WEB_TO_LEAD_FORM_IDS.yocoGateway}
              image={IMAGE_URL.yocoGateway}
              data-test-id="yoco-gateway-checkbox"
            />
          </div>
        </div>
        <Typography
          color={Color.Charcoal}
          text="Not sure about the products?"
          font="grotesk"
          size="body-lg"
          weight="medium"
          className="mb-8-v2 sm-v2:mb-16-v2"
        />

        <CheckBox
          label="I'm not sure"
          id={WEB_TO_LEAD_FORM_IDS.unsure}
          data-test-id="unsure-checkbox"
        />
      </>
    )
  }
}

export default FormProductOptions
